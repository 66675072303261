<template>
  <div class="good-detail">
    <div class="detail-img" v-if="goodDetail.productImageUrl">
      <img :src="goodDetail.productImageUrl | filterImg" alt="" />
    </div>

    <div style="padding: 0 10px; margin-top: -22px">
      <!-- 套餐信息 -->
      <productInfo :productInfo="goodDetail"></productInfo>
      <!-- 医院信息 -->
      <hospitalInfo
        :hospitalInfo="hospitalInfo"
        v-if="!hideHospital"></hospitalInfo>
      <!-- 预约时间 -->
      <appointmenTime
        :productId="id"
        @showCalendar="openCalendar"></appointmenTime>

      <calendar
        :hospitalProductId="id"
        :showPopup="showCalendar"
        :defaultDate="defaultDate"
        @onClosePopup="showCalendar = false"
        @onConfirm="onConfirmDate"></calendar>
      <div style="border-radius: 10px; overflow: hidden; margin-top: 10px">
        <van-tabs
          v-model="active"
          scrollspy
          sticky
          :offset-top="46"
          color="#00C4B3"
          title-active-color="#00C4B3"
          title-inactive-color="#878D99">
          <van-tab title="套餐项目" name="a">
            <template #title>
              <span class="font16 bold"> 套餐项目 </span>
            </template>
            <!-- 重点筛查 -->
            <keyScreening
              v-if="
                goodDetail.hospitalCode !== 'HS2022121520' &&
                goodDetail.projectDiseaseItems &&
                goodDetail.projectDiseaseItems.length
              "
              :projectDiseaseItems="
                goodDetail.projectDiseaseItems
              "></keyScreening>
            <!-- 套餐项 -->
            <projectInfo :projectInfo="goodDetail.portfolioItems"></projectInfo>
          </van-tab>
          <van-tab title="体检须知" name="b">
            <template #title>
              <span class="font16 bold"> 体检须知 </span>
            </template>
            <!-- 体检须知 -->
            <notice
              v-if="goodDetail.hospitalPortfolioNotice"
              :notice="goodDetail.hospitalPortfolioNotice"></notice>
            <!-- 关于退改 -->
            <aboutRefoud
              :productHospitalId="goodDetail.productHospitalId"
              :cancellationPolicy="goodDetail.cancellationPolicy"></aboutRefoud>
          </van-tab>
        </van-tabs>
      </div>
    </div>

    <footerButton
      :price="goodDetail.salePrice"
      @buyClick="buyClick"></footerButton>

    <van-popup v-model="showPopup" closeable position="bottom" round>
      <div class="popup-container">
        <h2 class="popup-title">选择预约方式</h2>
        <div class="popup-item" @click="addItem">
          <img src="@/assets/icon-pup-jiaxiang.png" alt="" />
          <div>
            <div class="popup-item-header">自选加项</div>
            <div class="popup-item-desc">
              可在定制套餐的基础上增加个性化体检项目，单项收费。更全面的满足您的个性化体检需求。
            </div>
          </div>
        </div>
        <div class="popup-item" @click="handlerBuy">
          <img src="@/assets/icon-pup-yuyue.png" alt="" />
          <div>
            <div class="popup-item-header">直接预约</div>
            <div class="popup-item-desc">
              不需要增加个性化体检项目，套餐可以满足您的需求。
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import productInfo from "@/views/components/goodDetail/productInfo.vue";
import keyScreening from "@/views/components/goodDetail/keyScreening.vue";
import projectInfo from "@/views/components/goodDetail/projectInfo.vue";
import notice from "@/views/components/goodDetail/notice.vue";
import hospitalInfo from "@/views/components/goodDetail/hospitalInfo.vue";
import appointmenTime from "@/views/components/goodDetail/appointmenTime.vue";
import aboutRefoud from "@/views/components/goodDetail/aboutRefoud.vue";
import footerButton from "@/views/components/goodDetail/footerButton.vue";
import calendar from "@/components/calendar.vue";

import { GetProductItem, GetHospital, PostStatistics } from "@/api";
export default {
  name: "good-detail",
  components: {
    productInfo,
    keyScreening,
    projectInfo,
    notice,
    aboutRefoud,
    hospitalInfo,
    appointmenTime,
    calendar,
    footerButton,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_FILE_BASEURL || "https://img.glztj.com",
      id: "",
      goodDetail: {},
      hospitalInfo: {},
      active: "a",
      time: "",
      aboutTiyanUp: true,
      showPopup: false, //是否显示加项弹窗
      showCalendar: false,
      latitude: "",
      longitude: "",
      defaultDate: {},
    };
  },
  computed: {
    hideHospital() {
      return this.$store.state.hideHospital;
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.$store.commit("setTitle", "套餐详情");
    //清除预约时间
    this.$store.commit("setAppointmentTime", {});
    this.geDetail();
    localStorage.removeItem("addItem");
    localStorage.removeItem("questionnaireResult");
    localStorage.removeItem("projects");
    this.sendStatistics(2);
  },
  methods: {
    async sendStatistics(trackingType) {
      const openId = this.$store.state.openid;
      if (!openId) return;
      await PostStatistics({
        openId: this.$store.state.openid,
        hospitalId: this.$store.state.hospitalId,
        hospitalProductId: this.id,
        trackingType,
      });
    },
    async buyClick() {
      this.sendStatistics(3);
      if (this.goodDetail.hasAdditionPackageItems) {
        //支持加项
        this.showPopup = true;
      } else {
        this.handlerBuy();
      }
    },
    //直接预约
    handlerBuy() {
      this.$router.push("/confirm-order/" + this.id);
    },
    //跳转加项
    addItem() {
      this.$store.commit("setOrderPrice", this.goodDetail.salePrice);
      this.$store.commit("setOrderProductName", this.goodDetail.productName);

      this.$router.push("/add-item/" + this.id);
    },
    async geDetail() {
      const params = {
        itemId: this.id,
      };
      let data = await GetProductItem(params);
      this.goodDetail = data;
      this.$store.commit("setViewProductId", this.goodDetail.productId);
      this.$store.commit("setHospitalId", this.goodDetail.hospitalCode);
      this.getHospitalInfo();
    },
    //获取医院详情
    async getHospitalInfo() {
      const res = await GetHospital({
        HospitalId: this.goodDetail.hospitalCode,
      });
      this.hospitalInfo = res;
      this.$store.commit("setHospitalInfo", this.hospitalInfo);
    },
    getChooseDays(item) {
      console.log(item);
      this.$store.commit("setAppointmentDate", item.period[0].time);
      this.$store.commit("setChooseDays", item.date);
    },
    //打开预约日历
    openCalendar(date) {
      this.defaultDate = date;
      this.showCalendar = true;
    },
    onConfirmDate(dateInfo) {
      this.buyClick();
      console.log(dateInfo);
    },
    //确认免费加项
    confirmFreeProject(projects) {
      localStorage.setItem("projects", JSON.stringify(projects));
      if (this.goodDetail.hasAdditionPackageItems) {
        this.showPopup = true;
      } else {
        this.handlerBuy();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.good-detail {
  overflow-y: auto;
  background: #f4f5f7;
  -webkit-overflow-scrolling: touch;
  .detail-img {
    height: 53.33vw;
    overflow-y: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .detail-header {
    padding: 0 20px;
    height: 146px;
    background: #fff;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    z-index: 1;
    position: relative;
    .detail-price {
      align-items: center;
      .salesVolume {
        font-size: 14px;
        color: #878d99;
      }
    }
    .detail-title {
      margin-top: 20px;
    }

    .card-types {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      span {
        padding: 3px 4px;
        margin: 0 5px 0 0;
        color: #0091ff;
        background: #f2f9ff;
        border-radius: 4px;
      }
    }
  }
  .detail-time {
    background: #fff;
    margin: 10px 0;
    padding: 16px 14px;
  }

  .item-box-content-word-item {
    font-size: 15px;
    color: #475266;
    line-height: 24px;
  }
  .about-tiyan {
    position: relative;
    padding-bottom: 40px;
    overflow: hidden;
    .tiyan-btn {
      position: absolute;
      height: 92px;
      background: transparent url("../assets/icon_img_mask@2x.png") no-repeat
        left top;
      background-size: 100%;
      width: 100%;
      bottom: -14px;
      text-align: center;
      .van-icon {
        position: absolute;
        bottom: 20px;
        font-size: 20px;
        margin: 0 auto;
      }
    }
  }
}
.tips-container {
  margin-top: 10px;
  padding: 8px 10px;
  background-color: #fff;
  font-size: 14px;
  line-height: 22px;
}
.tips {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 20px;
}

.popup-container {
  padding: 16px 16px 30px 16px;
}

.popup-item {
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-top: 8px;
  height: 120px;
  border-radius: 10px;
}

.popup-item:nth-child(even) {
  background: rgba(68, 215, 182, 0.1);
}

.popup-item:nth-child(odd) {
  background: rgba(0, 145, 255, 0.1);
}

.popup-item img {
  margin-right: 15px;
  width: 60px;
  height: 60px;
}

.popup-item-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  color: #2c2f37;
}

.popup-item-desc {
  margin-top: 4px;
  font-size: 13px;
  line-height: 20px;
  color: #475266;
}

.arrow-right {
  width: 20px;
  height: 20px;
}

.popup-text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 22px;
}

.popup-title {
  text-align: center;
  font-weight: bold;
  color: #2c2f37;
  font-size: 16px;
  margin-bottom: 20px;
}
</style>
